import styled from 'styled-components'
import { gray, white } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background: ${white};

  h2 {
    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 32px;
    }
  }

  .doc-container {
    gap: 16px;
  }

  .logo-container {
    gap: 24px;

    @media (min-width: ${breakpoints.md}) {
      gap: 40px;
    }
  }
`

export const DocLink = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: ${gray[400]};
  border: 1px solid ${gray[550]};

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    width: 332px;
  }

  .svg-pdf {
    width: 16px;
    height: 16px;
  }

  .svg-chevron {
    width: 24px;
    height: 24px;
  }
`
