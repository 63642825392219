/* eslint-disable max-len */
import React from 'react'
import IntercoIcons from '../../../components/IntercoIcons'
import data from './data.json'

// Logo
import logoANBIMAGestaoDeRecursos from '../../../images/logo-anbima-gestao-de-recursos.png'
import logoANBIMAGestaoDePatrimonio from '../../../images/logo-anbima-gestao-de-patrimonio.png'
import logoComoInvestir from '../../../images/logo-como-investir.png'

import * as S from './style'

interface IDoc {
  title: string;
  pdf: string;
}

interface IAcessoAInformacao {
  disclaimer: string;
}

const AcessoAInformacao = ({ disclaimer }: IAcessoAInformacao) => {
  const docs: IDoc[] = data
  return (
    <S.SectionWrapper id='principios-que-geram-resultados' className='py-5 bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-gray-750 f-sora fw-600 mb-4 pb-md-2 text-center'>
              Acesso à informação
            </h2>
            <p className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-400 f-sora text-gray-750 text-center mb-4'>
              Acesse nossos documentos de Compliance e Informes de Cotas
            </p>
          </div>
          <div className='col-12 mb-4 pb-2 pb-md-3'>
            <div className='d-flex flex-wrap justify-content-lg-center doc-container'>
              {docs.map((doc: IDoc) => (
                <S.DocLink href={doc.pdf} key={doc.title} className='d-flex justify-content-between p-3'>
                  <div className='d-flex align-items-center'>
                    <IntercoIcons icon='pdf-file' size='SM' className='svg-pdf' color='#6A6C72' />
                    <span className='fs-14 lh-17 fw-400 f-inter text-gray-750 d-block mb-0 ml-3'>{doc.title}</span>
                  </div>
                  <IntercoIcons icon='chevron-right' size='LG' className='svg-chevron' color='#FF7A00' />
                </S.DocLink>
              ))}
            </div>
          </div>
          <div className='col-12'>
            <div className='d-flex flex-wrap justify-content-center logo-container'>
              <img src={logoANBIMAGestaoDeRecursos} alt='Logo Anbima - Gestão de Recursos' />
              <img src={logoANBIMAGestaoDePatrimonio} alt='Logo Anbima - Autoregulação' />
              <img src={logoComoInvestir} alt='Logo como investir' />
            </div>
          </div>
          <div className='col-12 mt-4'>
            <p className='fs-12 lh-15 text-gray-750 f-inter'>
              {disclaimer}
            </p>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default AcessoAInformacao
